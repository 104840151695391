


export const layouts = {
'index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/index.vue'),
'components/AppSetting/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/AppSetting/index.vue'),
'components/Header/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/Header/index.vue'),
'components/Logo/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/Logo/index.vue'),
'components/MainSidebar/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/MainSidebar/index.vue'),
'components/Search/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/Search/index.vue'),
'components/SubSidebar/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/SubSidebar/index.vue'),
'components/SidebarItem/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/SidebarItem/index.vue'),
'components/Tools/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/Tools/index.vue'),
'components/Topbar/index': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/Topbar/index.vue'),
'components/views/link': () => import('/D:/workspace/03.self_dev/CodeReviewServer_Portal/src/layouts/components/views/link.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'index'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
